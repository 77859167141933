import './Header.css';
import $ from 'jquery';
window.$ = $;

const scrollTo = (dest) => {          
    closeMenu();      
    if($(dest).length == 0) return;
    window.TweenMax.to('html,body', 0.6, {
        scrollTop: $(dest).offset().top - 140,
        ease: window.Power4.easeInOut
    });
}

const openMenu = () => {
    window.TweenMax.to('.mobile-menu', 0.6, {
        x: 0,
        ease: window.Power4.easeInOut
    });
    window.TweenMax.to('.header-mobile', 0.6, {
        opacity: 0.2,
        ease: window.Power4.easeInOut
    });
}

const closeMenu = () => {
    window.TweenMax.to('.mobile-menu', 0.6, {
        x: '-100%',
        ease: window.Power4.easeInOut
    });
    window.TweenMax.to('.header-mobile', 0.6, {
        opacity: 1,
        ease: window.Power4.easeInOut
    });
}

export default function Header(){
    
    return (
        <>
            <div className="mobile-menu">
                <a href="#" onClick={e => {e.preventDefault(); closeMenu();}} className="mobile-menu-close">
                    <img src="/x.png" className="mx-auto mx-0 inline-block" />
                </a>
                <a href="#" onClick={e => {e.preventDefault(); scrollTo('#scroll-top'); closeMenu();}}>
                    <img src="/logo-text.png" className="mx-auto mx-0 inline-block mobile-menu-logo" />
                </a>
                <ul>
                    <li><a href="#" onClick={e => {e.preventDefault(); scrollTo('#section-about'); closeMenu();}}>About</a></li>
                    <li><a href="#" onClick={e => {e.preventDefault(); scrollTo('#section-roadmap'); closeMenu();}}>Roadmap</a></li>
                    <li><a href="#" onClick={e => {e.preventDefault(); scrollTo('#section-team'); closeMenu();}}>Team</a></li>
                    <li><a href="#" onClick={e => {e.preventDefault(); scrollTo('#section-faq'); closeMenu();}}>FAQ</a></li>
                </ul>
                <div className="social text-center pr-0 md:pr-12 md:text-right pt-12 md:pt-0">
                    <button onClick={e => scrollTo('#section-mint')} className="header-cta">Mint</button>
                    <a href={process.env.REACT_APP_TWITTER} target="_blank"><img src="/twitter.png" /></a>
                    <a href={process.env.REACT_APP_DISCORD} target="_blank"><img src="/discord.png" /></a>
                    <a href={process.env.REACT_APP_INSTAGRAM} target="_blank"><img style={{width: 36, height: 'auto'}} src="/instagram.png" /></a>
                    <a href={process.env.REACT_APP_OPENSEA} target="_blank"><img src="/opensea.png" /></a>
                    {/* <a href="https://opensea.com" target="_blank"><img src="/opensea.png" style={{marginLeft: 3, width: 29, height: 'auto'}} /></a> */}
                </div>
            </div>
            <div className="header-mobile py-4">
                <div className="burger">
                    <a href="#" onClick={e => {e.preventDefault(); openMenu();}}>
                        <img src="/burger.png" alt="" />
                    </a>
                </div>
                <div style={{flexGrow: 1, textAlign: 'right', paddingRight: 20}}>
                    <a href="#" onClick={e => {e.preventDefault(); scrollTo('#scroll-top')}}>
                        <img src="/logo.png" className="mx-auto mx-0 inline-block" style={{width: 50}} />
                    </a>
                </div>
            </div>

            <div className="header-desktop py-12 md:py-4 md:fixed">

                <div className="logo pl-0 md:pl-12">
                    <a href="#" onClick={e => {e.preventDefault(); scrollTo('#scroll-top')}}>
                        <img src="/logo-text.png" alt="logo" className="mx-auto mx-0" />
                    </a>
                </div>

                <div className="menu text-center pt-12 md:pt-0">
                    
                </div>
            
                <div className="social text-center pr-0 md:pr-12 md:text-right pt-12 md:pt-0">
                    <button onClick={e => scrollTo('#section-mint')} className="header-cta">Mint</button>
                    <a href={process.env.REACT_APP_TWITTER} target="_blank"><img src="/twitter.png" /></a>
                    <a href={process.env.REACT_APP_DISCORD} target="_blank"><img src="/discord.png" /></a>
                    <a href={process.env.REACT_APP_INSTAGRAM} target="_blank"><img style={{width: 36, height: 'auto'}} src="/instagram.png" /></a>
                    <a href={process.env.REACT_APP_OPENSEA} target="_blank"><img style={{width: 30, height: 'auto'}} src="/opensea.png" /></a>
                    {/* <a href="https://opensea.com" target="_blank"><img src="/opensea.png" style={{marginLeft: 3, width: 29, height: 'auto'}} /></a> */}
                </div>

            </div>
        </>
    );

}