import './Roadmap.css';
export default function Roadmap(props){
    return (<div className="roadmap">

        <ul className="roadmap-circle">


            <li className="roadmap-item">
                <div className="oval-shape" style={{border: '3px solid #6de6b7'}}>
                    <span>1</span>
                </div>
                <div className="roadmap-desc roadmap-desc-front">
                    <h1 className="text-green-300">THE CLUB</h1>
                    <p>The PantherEGO team will be completely invested in growing a solid community. Together, with our earliest supporters, we will work on the development of the PantherEGO future and all it’s creative potential! Delivering amazing art to the members who believe in our project is our main source of inspiration to work hard at creating a long lasting and profitable project to all holders!</p>
                </div>
            </li>

            <li className="roadmap-item">
                <div className="oval-shape" style={{border: '3px solid #a5b4fb'}}>
                    <span>2</span>
                </div>
                <div className="roadmap-desc roadmap-desc-front">
                    <h1 className="text-indigo-300">THE BEGINNING</h1>
                    <p>Engagement and participation will be key in our dynamic Whitelist system, in which members will get presale access to PantherEGO minting at a lower price. Let’s build the hype! We will have raffles and giveaways for Whitelist spots on our Discord server, Twitter, and tons of promotional strategy! </p>
                </div>
            </li>

            <li className="roadmap-item">
                <div className="oval-shape" style={{border: '3px solid #f9a8d4'}}>
                    <span>3</span>
                </div>
                <div className="roadmap-desc roadmap-desc-front">
                    <h1 className="text-pink-300">GIVING BACK!</h1>
                    <p>Because of a personal, sentimental motivation of the PantherEGO artist and team, we will donate 15% of the total profits to the Operation Smile non profit organization.</p>
                </div>
            </li>

            <li className="roadmap-item">
                <div className="oval-shape" style={{border: '3px solid #6de6b7'}}>
                    <span>4</span>
                </div>
                <div className="roadmap-desc roadmap-desc-front">
                    <h1 className="text-green-300">MEMBER LOYALTY</h1>
                    <p className="bullet-item"> Keep an eye out for minting a Panther with a tattoo, a total of ten (10) PantherEGOs will have a tattoo and if you mint one, you will be eligible to claim a tattoo session with the amazing creator of our Panthers (more info on transferability & period of validity to come once you claim). For reference you can check out the artist’s work on his IG portfolio @eig.tattoo. Each session given out has a $1800 value.</p>
                    <p className="bullet-item"> If you hold two (2) Panthers, you will be elegible to claim a free Hugo the Horse NFT, just pay gas! </p>
                    <p className="bullet-item"> If you mint an immortal PantherEGO you will be elegible to receive a free extra Panther (+gas), therefore once you hold two (2) Panthers, you will also receive a free Hugo the Horse (+gas)! </p>
                    <p className="bullet-item"> When the PantherEGO collection sells out, those who minted an immortal Panther, and have claimed their free extra Panther + Hugo the Horse, these holders will be elegible to receive a free NFT from our artist’s 2nd collection!</p>
                    <p className="bullet-item"> If you hold the total amount of PantherEGOs that are able to be minted (10 Panthers) you get immediate Whitelist access to Eigleer’s upcoming original full-body NFT collection which is intended to honor the history of body art and tattooing. </p>
                </div>
            </li>

            <li className="roadmap-item">
                <div className="oval-shape" style={{border: '3px solid #ec4899'}}>
                    <span>5</span>
                </div>
                <div className="roadmap-desc roadmap-desc-front">
                    <h1 style={{color: '#ec4899'}}>MAIN EVENT</h1>
                    <p>MINT, MINT, MINT!</p>
                    <p>When our collection sells out we will have a giveaway of 10 ETH to ten (10) PantherEGO holders!</p>
                </div>
            </li>

            <li className="roadmap-item">
                <div className="oval-shape" style={{border: '3px solid #ffcc00'}}>
                    <span>6</span>
                </div>
                <div className="roadmap-desc roadmap-desc-front">
                    <h1 style={{color: '#ffcc00'}}>THE FUTURE</h1>
                    <p>After sell out, our plans for our next project include a full-body 3D modeled 1:1 original character NFT collection that we intend to incorporate into the Metaverse. This upcoming collection will honor the history of body art and tattooing. From creating interactive character viewings in the Metaverse, the development of a tattoo designing app for your NFT, to super dope merchandise… the possibilities are endless! You won’t want to miss it!</p>
                </div>
            </li>

        
        </ul>

                
    
    </div>);
}