import { useGlobal, useEffect } from 'reactn';
import { mintWhitelist, connectWallet } from './lib/blockchain';
import { Toaster } from 'react-hot-toast';
import moment from 'moment-timezone';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Header from './components/Header';
import Marquee from './components/Marquee';
import Roadmap from './components/Roadmap';
import Team from './components/Team';
import FAQ from './components/FAQ';
import Hero from './components/Hero';
import Countdown from './components/Countdown';
import Mint from './components/Mint';

// const whitelistAddresses = require('./whitelist.json');
// const { MerkleTree } = require('merkletreejs');
// const SHA256 = require('crypto-js/sha256');


function App() {

    
    useEffect(() => {
        AOS.init();
    }, []);
    
    return (
        <div className="App" id="scroll-top">

            
            <div className="section pt-20 md:pt-40" data-aos="zoom-in" data-aos-delay="400" data-aos-duration="1000" id="section-roadmap">
                <div className="col-1">
                    <h2 className="text-center">Roadmap</h2>
                    <Roadmap />
                </div>
            </div>

           
            
            <div className="footer py-40">
                <div className="text-center text-gray-500">
                    Project not affiliated with MGM or any third party.<br />
                    All Rights Reserved 2022.<br />
                    {/* <a href="/terms.pdf" target="_blank">Terms and conditions</a>
                    <br /><br />
                    0x123321312312312312321323232 */}
                </div>
            </div>
            
            <canvas id="confetti"></canvas>
        </div>
    );

}

export default App;
